<template>
  <div id="main">
    <el-card class="header-card" v-if="newsList.length">
      <el-carousel :interval="4000" type="card" height="200px">
        <el-carousel-item v-for="(item,index) in newsList" v-if="index<4">
          <router-link :to="{path:'/servicesCase/Content',query:{News:item}}">
          <img :src="item.coverUrl" alt="">
          <div class="hover-title">{{ item.title.length < 25 ? item.title : item.title.substring(0, 25) + '...' }}</div>
          </router-link>
        </el-carousel-item>
      </el-carousel>
      <div class="new-icon"><img src="../../images/new.png" alt=""></div>
    </el-card>
    <div style="width: 100%;" v-else>
      <el-empty description="暂时没有数据 . . ."></el-empty>
    </div>
    <el-card class="content-card" v-if="newsList.length && newsList.length>4">
      <div class="content-card-title">基地动态</div>
      <el-divider><i style="color: #42b983" class="el-icon-star-off"></i></el-divider>
      <div class="news-card">
        <ul>
          <li v-for="(item,index) in newsList" v-if="index>3">
            <el-skeleton style="width: 240px;" :loading="loading" animated>
              <template slot="template">
                <el-skeleton-item
                    variant="image"
                    class="news-img"
                />
                <div class="news-card-content">

                  <div class="news-date">
                    <el-skeleton-item variant="text" style="display:flex;width: 50%; margin-left: 0;"/>
                    <el-skeleton-item variant="text" style="display:flex;width: 70%; margin: 10px 0 0 0"/>
                  </div>
                  <div class="news-title">
                    <el-skeleton-item variant="h1" style="display:flex;width: 100%; margin: 10px 0 0 0"/>
                  </div>
                </div>
              </template>
              <template>
                <router-link :to="{path:'/servicesCase/Content',query:{News:item}}">
                  <div class="news-img"><img :src="item.coverUrl" alt=""></div>
                  <div class="news-card-content">
                    <div class="news-date">
                      <div class="day">{{ new Date(item.creatTime).format("dd") }}</div>
                      <div class="year">{{ new Date(item.creatTime).format("yyyy-MM") }} <span>>>></span></div>
                    </div>
                    <div class="news-title">{{ item.title }}</div>
                  </div>
                </router-link>
              </template>
            </el-skeleton>
          </li>
          <div v-if="newsList===0||newsList===null" style="color: #909399">
            <el-empty :image-size="200"></el-empty>
          </div>
        </ul>
      </div>
    </el-card>


  </div>
</template>

<script>
export default {
  name: "baseDynamics",
  data() {
    return {
      count: 0,
      loading: true,
      newsList: []
    }
  },
  methods: {
    /**
     *
     *  获取新闻模块数据
     **/
    async getNews(num) {
      await this.$http.get("/base/selectBaseDynamics/" + num)
          .then(({data}) => {
            this.loading = false
            this.newsList = data
            console.log(data)
          })
          .catch((error) => {
            this.loading = true
          })
    },
  },
  mounted() {
    this.getNews(20)
  }
}
</script>

<style scoped>
#main {
  position: relative;
  width: 1200px;
  margin: 110px auto 0 auto;
}

/deep/ .el-card__body {
  padding: 0;
}

.header-card {
  /*position: relative;*/
  margin-top: 10px;
  overflow: hidden;
  height: 300px;
}

.header-card img {
  width: 100%;
  height: 100%;
  transition: all 0.3s;
}

.new-icon {
  position: absolute;
  top: 10px;
  right: 0;
  width: 65px;
  height: 65px;
}

.new-icon img {

  width: 65px;
  height: 65px;
  border-radius: 5px 10px
}

.hover-title {
  position: absolute;
  display: block;
  width: 100%;
  height: 45px;
  bottom: 0;
  line-height: 45px;
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  color: #fff;
  z-index: 99;
}

.content-card-title {
  font-size: 18px;
  color: #42b983;
  text-align: center;
}


.content-card {
  margin: 20px 0;
  padding: 20px;

}

.el-carousel {
  width: 90%;
  margin: 45px auto;
}


.news-card {
  text-align: center;
}

.news-card li {
  display: inline-block;
  width: 250px;
  height: 280px;
  margin: 10px 15px;
  vertical-align: top;
  text-align: left;
}

.news-img {
  width: 250px;
  height: 155px;
  border: 1px rgba(149, 149, 149, 0.3) solid;
  border-bottom: 0;
  border-radius: 5px 5px 0 0
}

.news-card-content {
  width: 250px;
  height: 125px;
  border: 1px rgba(149, 149, 149, 0.3) solid;
  border-top: 0;
  border-radius: 5px;
  padding: 5px;
}

.news-date {
  width: 215px;
  height: 50px;
  margin: 0 auto;
  padding: 2px;
  border-bottom: 1px #959595 solid;

}

.news-title {
  width: 215px;
  height: 60px;
  margin: 0 auto;
}

.news-card li .day {
  font-size: 18px;
  text-align: left;
  color: #959595;
  padding-left: 0.5em;
}

.news-card li .year {
  position: relative;
  text-align: left;
  font-size: 12px;
  color: #959595;
}

.news-card li .year span {
  display: inline-block;
  position: absolute;
  right: 0;
  color: #42b983;
  font-size: 14px;
}

.news-img img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: all 0.6s;
}

.news-img {
  overflow: hidden;
}

.news-img img:hover {
  transform: scale(1.4);
}

</style>