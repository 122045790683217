<template>
  <div id="main">
    <el-card class="content" shadow="hover">
      <h2>{{ content.title }}</h2>
      <div class="info">发布时间：{{ new Date(content.currentTime).format("yyyy-MM-dd") }}</div>
      <el-divider></el-divider>
      <div v-html="content.content"></div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: {}
    }
  },
  methods: {},
  async mounted() {

    //有数据拿数据，没数据拿缓存
    if (this.$route.query.content.content === undefined) {
      this.content = JSON.parse(window.sessionStorage.getItem('content'))

    } else {
      window.sessionStorage.setItem("content", JSON.stringify(this.$route.query.content))
      this.content = JSON.parse(window.sessionStorage.getItem('content'))
    }


  }
}

</script>

<style scoped>
#main {
  width: 1200px;
  margin: 110px auto 40px auto;
}

h2 {
  margin-top: 45px;
  text-align: center;
}

.info {
  margin-top: 25px;

  text-indent: 2em;

  border-radius: 5px;
}

.content {
  margin-top: 20px;
}
</style>
