<template>
  <div id="main" v-cloak>
    <div class="classify"><span class="VerticalLine"></span><span class="content">招聘分类</span>
      <el-divider></el-divider>
      <div class="classify-content">
        <el-radio-group v-model="page.category" size="mini" @change="radioBtn">
          <el-radio-button label="全部招聘"></el-radio-button>
          <el-radio-button v-for="item in categoryList" :label="item.category"></el-radio-button>
        </el-radio-group>
      </div>
    </div>

    <div class="card">
      <el-card class="box-card" shadow="hover">
        <div slot="header" class="clearfix">
          <h3>招聘信息</h3>
        </div>
        <div class="card-title-date">标题 <span style="display: inline-block;position: absolute; right: 0;">发布日期</span>
        </div>
        <el-divider class="card-line"></el-divider>
        <div v-if="!page.recruitmentList" class="nullData">暂时没有<b>{{ page.category!=='全部招聘'? page.category: '' }}</b>招聘信息</div>
        <div v-for="item in page.recruitmentList" :key="item.id" class="card-content">
         <router-link :to="{path:'/recruitment/RecruitmentContent',query:{content:item}}">
           {{ item.title }}
          <span
              style="display: inline-block;position: absolute; right: 0;">{{
              new Date(item.currentTime).format("yyyy-MM-dd")
            }}</span>
         </router-link>
        </div>
      </el-card>
    </div>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="page.currentPageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "RecruitmentMain",
  data() {
    return {
      categoryList: [],
      total: 0,
      page: {
        category: '全部招聘',
        currentPage: 1,
        currentPageSize: 10,
        recruitmentList: [],
      }
    }
  },
  methods: {
    /**
     *
     *
     **/
    getRecruitments(page) {
      this.$http.get('/Recruitments/Category?category=' + page.category + "&currentPage=" + page.currentPage + "&currentPageSize=" + page.currentPageSize).then(({data}) => {
        console.log(data)
        if (data.code === 200) {
          this.total = data.obj.count
          return this.page.recruitmentList = data.obj.data
        }
        this.page.recruitmentList = null
        this.total = 0
      })
    },
    /**
     *
     *  获取招聘类别
     **/
    getCategory() {
      this.$http.get('/RecruitmentCategory').then(({data}) => {
        this.categoryList = data.obj
      })
    },
    /**
     *
     *  分类按钮事件
     **/
    radioBtn(category) {
      this.page.currentPage=1
      this.page.category = category
      this.getRecruitments(this.page)
    },
    /**
     *
     *  页面大小改变事件
     **/
    handleSizeChange(pageSize) {
      this.page.currentPageSize = pageSize
      this.getRecruitments(this.page)
    },
    /**
     *
     *  当前页改变事件
     **/
    handleCurrentChange(currentPage) {
      this.page.currentPage =currentPage
      this.getRecruitments(this.page)
    }
  },
  mounted() {
    this.getCategory()
    this.getRecruitments(this.page)
  },
  created() {

  }
}
</script>

<style scoped>
a{
  color: #000;
}
a:hover{
  color: #409EFF;
}
#main {
  width: 1200px;
  height: 100%;
  margin: 110px auto 0 auto;
}

.card {
  margin-top: 45px;

}


.classify {
  margin-top: 60px;
  color: #409EFF;
}

.classify-content {
  margin-top: 10px;
  text-align: center;
}

.classify .classify-content {
  text-align: left;
}

.content {
  display: inline-block;
  vertical-align: top;
}

.VerticalLine {
  display: inline-block;
  width: 3px;
  height: 20px;
  border-radius: 5px;
  margin-right: 10px;
  vertical-align: center;
}

.classify .VerticalLine {
  background-color: #409EFF;
}

.el-radio-button {
  margin-bottom: 10px;
}

.el-divider {
  margin: 0;
}

/deep/ .el-radio-button__inner {
  width: 100px;
  margin-right: 20px;
  border-radius: 0px !important;
  border: 1px #E9E9EB solid;
}

.card-title-date {
  position: relative;
  height: 60px;
  line-height: 60px;
}

.card-line {
  position: absolute;
  left: 0;

}

/deep/ .el-card__body {
  position: relative;
  padding-top: 0;
}

.card-content {
  position: relative;
  margin: 10px 0
}

.nullData {
  height: 200px;
  margin: 0 auto;
  text-align: center;
  line-height: 200px;
  color: #909399;
}

.el-pagination {
  margin: 10px 0;
}
</style>
